import React from "react";
import { DispatchType, TopicListItemType } from "../../../../store/state-type";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import {
  SELECT_TOPIC_ITEM_FILTER,
  UNSELECT_ALL_TOPIC_FILTER,
} from "../../../../store/action-types.ts";
import { Button, Chip, Divider } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store_v2/store.ts";
import { updateFilterOnClick } from "../../../../store_v2/actions/tableSlice.ts";

type TopicMenuType = {
  topicList: TopicListItemType[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.RefObject<HTMLButtonElement>;
};

export default function TopicMenu({
  topicList,
  open,
  setOpen,
  anchorRef,
}: TopicMenuType) {
  const dispatch = useDispatch<AppDispatch>();

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    //@ts-expect-error [ignore]
    else if (event.target.id === "subject-button-filter") return;
    setOpen(false);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleOnClickMenuItem = (
    event: Event | React.SyntheticEvent,
    listItemIdx: number
  ) => {
    dispatch(
      updateFilterOnClick({
        filterListKey: "topicList",
        filterIdx: listItemIdx,
      })
    );
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{ zIndex: 5 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper sx={{ my: 0.5 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="list-menu"
                aria-labelledby="list-button"
                onKeyDown={handleListKeyDown}
                sx={{
                  width: "560px",

                  textAlign: "justify",
                  px: 2,
                  pt: 1,
                  pb: 1,
                }}
              >
                <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  {topicList.map((listItem) => {
                    return (
                      <Chip
                        key={listItem.id}
                        label={listItem.name}
                        variant="filled"
                        size="small"
                        onClick={(e) =>
                          handleOnClickMenuItem(e, listItem.index)
                        }
                        sx={{
                          my: 0.4,
                          mx: 0.4,
                          backgroundColor: listItem.isSelected
                            ? "primary.main"
                            : "rgba(0, 0, 0, 0.04)",
                          color: listItem.isSelected
                            ? "primary.contrastText"
                            : "primary.text",
                          boxShadow: 0.5,
                          border: 0.5,
                          ":hover": listItem.isSelected
                            ? {
                                backgroundColor: "primary.main",
                                color: "primary.contrastText",
                              }
                            : {},
                        }}
                      />
                    );
                  })}
                </div>
                <Divider variant="fullWidth" sx={{ mt: 1 }} />
                <Button
                  startIcon={<RestartAltIcon />}
                  size="small"
                  onClick={() => dispatch({ type: UNSELECT_ALL_TOPIC_FILTER })}
                >
                  Reset
                </Button>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
