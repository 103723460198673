import axios from "../config/axios.ts";

export default async function pingPong() {
  return axios
    .get(`/users/check_login/`)
    .then((_) => {
      return;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}
