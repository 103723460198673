import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PrefetchTableListType,
  TableListType,
  TableStateType,
} from "../../store/state-type.ts";
import {
  DEFAULT_IS_SUGGESTED_BOOL,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_SEARCH_STRING,
  DEFAULT_STATUS_LIST,
  DEFAULT_SUBJECT_LIST,
} from "../../constants/table-default-states.ts";
import preFetchTableList from "../../handlers/prefetch-table-list.ts";
import postFetchTableList from "../../handlers/postfetch-table-list.ts";

type SelectableFilterListKeyType =
  | "subjectList"
  | "listList"
  | "topicList"
  | "patternList"
  | "statusList";

const initialState: TableStateType = {
  filters: {
    isLoading: true,
    subjectList: DEFAULT_SUBJECT_LIST,
    listList: [],
    topicList: [],
    patternList: [],
    statusList: DEFAULT_STATUS_LIST,
    searchString: DEFAULT_SEARCH_STRING,
    isSuggested: DEFAULT_IS_SUGGESTED_BOOL,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    currentPage: DEFAULT_PAGE_NUMBER,
  },
  data: {
    isLoading: true,
    previous: null,
    next: null,
    count: 0,
    list: [],
  },
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    /** Filters */
    setFilters: (
      state,
      action: {
        payload: {
          data: TableStateType["filters"];
          // searchParams: URLSearchParams;
        };
      }
    ) => {
      const { data } = action.payload;
      const { filters } = state;

      filters.isLoading = false;
      filters.currentPage = DEFAULT_PAGE_NUMBER;
      // parseInt(searchParams.get("page") ?? `${DEFAULT_PAGE_NUMBER}`);
      filters.rowsPerPage = DEFAULT_ROWS_PER_PAGE;
      // parseInt(searchParams.get("page_size") ?? `${DEFAULT_ROWS_PER_PAGE}`);
      filters.subjectList = data.subjectList;
      filters.listList = data.listList;
      filters.topicList = data.topicList;
      filters.patternList = data.patternList;
    },
    updateFilterOnClick: (
      state,
      action: {
        payload: {
          filterListKey: SelectableFilterListKeyType;
          filterIdx: number;
        };
      }
    ) => {
      const { filterListKey, filterIdx } = action.payload;
      const filter = state.filters[filterListKey];
      filter[filterIdx] = {
        ...filter[filterIdx],
        isSelected: !filter[filterIdx].isSelected,
      };
      state.filters.currentPage = DEFAULT_PAGE_NUMBER;
    },
    toggleIsSuggestedFilter: (state, action: { payload: boolean }) => {
      const filters = state.filters;
      filters.isSuggested = action.payload;
      filters.currentPage = DEFAULT_PAGE_NUMBER;
    },
    updateSearchString: (state, action: { payload: string }) => {
      const filters = state.filters;
      filters.searchString = action.payload;
      filters.currentPage = DEFAULT_PAGE_NUMBER;
    },
    updateSubjectFilterOnClick: (state, action: { payload: number }) => {
      const subjectList = state.filters.subjectList;
      const idx = action.payload;
      console.log(idx);
      if (idx === 0) {
        console.log("idx=0 start");
        if (!subjectList[0].isSelected) {
          subjectList.forEach((subject) => {
            subject.isSelected = false;
          });
          subjectList[0].isSelected = true;
        }
        console.log("idx=0 end");
      } else {
        if (subjectList[idx].isSelected) {
          var isAnySelected: boolean = false;
          subjectList.forEach(
            (subject) =>
              subject.index !== idx && (isAnySelected ||= subject.isSelected)
          );

          if (!isAnySelected) subjectList[0].isSelected = true;
        } else {
          subjectList[0].isSelected = false;
        }
        subjectList[idx].isSelected = !subjectList[idx].isSelected;
      }

      state.filters.currentPage = DEFAULT_PAGE_NUMBER;
    },

    setRowsPerPage: (state, action: { payload: number }) => {
      const filters = state.filters;
      filters.rowsPerPage = action.payload;
      filters.currentPage = DEFAULT_PAGE_NUMBER;
    },
    setCurrentPageNumber: (state, action: { payload: number }) => {
      const filters = state.filters;
      filters.currentPage = action.payload;
    },
    /** Data */
    setTableDataList: (
      state,
      action: {
        payload: {
          count: number;
          list: PrefetchTableListType[];
          next: string | null;
          previous: string | null;
        };
      }
    ) => {
      const { count, list, next, previous } = action.payload;
      state.data.count = count;
      state.data.next = next;
      state.data.previous = previous;
      state.data.list = list;
    },
    // postsetTableDataList: (
    //   state,
    //   action: { payload: { count: number; list: TableListType[] } }
    // ) => {
    //   state.data.list = action.payload.list;
    //   state.data.count = action.payload.count;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(updatePreTableDataAsync.pending, (state) => {
        state.data.isLoading = true;
      })
      .addCase(updatePreTableDataAsync.fulfilled, (state) => {
        state.data.isLoading = false;
      })
      .addCase(updatePreTableDataAsync.rejected, (state) => {
        state.data.isLoading = true;
      })
      .addCase(updatePostTableDataAsync.fulfilled, (state) => {
        state.data.isLoading = false;
      })
      .addCase(updatePostTableDataAsync.rejected, (state) => {
        state.data.isLoading = true;
      });
  },
});
export const updatePreTableDataAsync = createAsyncThunk(
  "user/updatePreTableDataAsync",
  async (
    { filterParamUrl, signal }: { filterParamUrl: string; signal: AbortSignal },
    { dispatch }
  ) => {
    try {
      const res = await preFetchTableList(filterParamUrl, signal);
      dispatch(setTableDataList(res));
    } catch (error) {
      // snackbar
    }
  }
);
export const updatePostTableDataAsync = createAsyncThunk(
  "user/updatePostTableDataAsync",
  async (
    { filterParamUrl, signal }: { filterParamUrl: string; signal: AbortSignal },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await postFetchTableList(filterParamUrl, signal);
      dispatch(setTableDataList(res));
    } catch (error) {
      // snackbar
      return rejectWithValue(error);
    }
  }
);

export const {
  setFilters,
  updateFilterOnClick,
  toggleIsSuggestedFilter,
  updateSearchString,
  updateSubjectFilterOnClick,
  setRowsPerPage,
  setCurrentPageNumber,
  setTableDataList,
} = tableSlice.actions;
export default tableSlice.reducer;
