import React, { forwardRef } from "react";
import fetchProblemDetails from "../../../handlers/fetch-problem-details.ts";
import fetchUserAttemptDetails from "../../../handlers/fetch-user-attempt-details.ts";
import {
  ProblemDetailsType,
  UserAttemptType,
} from "../../../store/state-type.ts";
import ProblemDialogHeader from "./dialog-header.tsx";
import DialogLeftContent from "./dialog-content-left.tsx";
import DialogRightContent from "./dialog-content-right.tsx";

/** MUI */
import Grid from "@mui/material/Unstable_Grid2";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import { DialogUseRefType } from "../problemset/problemset-main.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../store_v2/store.ts";

type ProblemDialogMainType = {
  problemId: string | null;
  navigateToProblemset: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogPaperProps = {
  sx: {
    height: "calc(100vh - 50px - 10vh)",
    p: 2,
    width: "100%",
    maxWidth: "85vw",
  },
};

const ProblemDialogMain = forwardRef<DialogUseRefType, ProblemDialogMainType>(
  ({ problemId, navigateToProblemset }, ref) => {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
    const [userAnswer, setUserAnswer] = React.useState<string | null>(null);

    const [problemDetails, setProblemDetails] =
      React.useState<ProblemDetailsType | null>(null);
    const [userAttemptDetails, setUserAttemptDetails] =
      React.useState<UserAttemptType | null>(null);

    /**  */
    React.useEffect(() => {
      if (isLoggedIn === false) setUserAttemptDetails(null);
    }, [isLoggedIn]);

    /** Fetch Problem Details */
    React.useEffect(() => {
      async function apiCall1() {
        if (problemId) {
          const detailsRes = await fetchProblemDetails(problemId);
          setProblemDetails(detailsRes);
        }
      }
      async function apiCall2() {
        if (problemId) {
          const userRes = await fetchUserAttemptDetails(problemId);
          setUserAttemptDetails(userRes);
        }
      }
      apiCall1();
      apiCall2();
    }, [problemId]);

    /** Dialog Actions */
    const handleDialogClose = () => {
      if (isDialogOpen === false) return;
      setIsDialogOpen(false);
      navigateToProblemset();
    };
    const handleDialogOpen = () => {
      if (isDialogOpen === true) return;
      setIsDialogOpen(true);
    };
    const handleDialogCleanUp = () => {
      setUserAnswer(null);
      setUserAttemptDetails(null);
      setProblemDetails(null);
    };

    /** Actions */
    React.useImperativeHandle(ref, () => ({
      openDialog() {
        handleDialogOpen();
      },
      closeDialog() {
        handleDialogClose();
      },
    }));

    return (
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="problem-modal-title"
        TransitionComponent={Transition}
        TransitionProps={{
          onExited: () => {
            handleDialogCleanUp();
          },
        }}
        PaperProps={DialogPaperProps}
      >
        <DialogTitle component="div" sx={{ p: 0, mb: 1 }}>
          <ProblemDialogHeader
            problemId={problemId}
            handleDialogClose={handleDialogClose}
            tags={problemDetails?.tags}
            userAttempt={userAttemptDetails}
          />
        </DialogTitle>
        <Divider variant="fullWidth" />
        <DialogContent
          sx={{
            px: 2,
            pb: 0,
            height: "fit-content",
            overflow: "hidden",
          }}
          // dividers
        >
          <Grid container spacing={3} height="100%">
            <Grid xs={7} height="100%" sx={{ overflowY: "scroll" }}>
              <DialogLeftContent
                title={problemDetails?.title}
                description={problemDetails?.description}
              />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid xs={5} height="100%" pb={0} sx={{ position: "relative" }}>
              <DialogRightContent
                userAnswer={userAnswer}
                setUserAnswer={setUserAnswer}
                problemDetails={problemDetails}
                userAttemptDetails={userAttemptDetails}
                setUserAttemptDetails={setUserAttemptDetails}
                problemId={problemId}
                handleDialogClose={handleDialogClose}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
);
export default ProblemDialogMain;
