import axios from "../config/axios.ts";
import { QUESTION_TYPE, STATUS_TYPE } from "../constants/problem-states.ts";
import type {
  PrefetchTableListType,
  TableListType,
} from "../store/state-type.ts";

type ResponseTagType = { name: string };
type ResponseType = {
  title: string;
  question_type: string;
  reference_id: string;
  tags: ResponseTagType[];
  
};

function strToQuestionType(status: string) {
  switch (status) {
    case "SU":
      return QUESTION_TYPE.SUBJECTIVE;
    case "SC":
      return QUESTION_TYPE.SINGLE_CORRECT;
    default:
      return QUESTION_TYPE.UNKOWN;
  }
}

function convertResultsToList(
  resultList: ResponseType[]
): PrefetchTableListType[] {
  return resultList.map((result) => {
    return {
      tags: result.tags.map((tag: ResponseTagType) => {
        return {
          name: tag.name,
        };
      }),
      title: result.title,
      id: result.reference_id,
      questionType: strToQuestionType(result.question_type),
    };
  });
}

export default async function preFetchTableList(
  filterParamUrl: string,
  signal: AbortSignal
) {
  // dispatch({
  //   type: SET_PROBLEM_TABLE_LOADING,
  //   value: true,
  // });
  return await axios
    .get(`/core/questions/?${filterParamUrl}`, {
      signal: signal,
    })
    .then(
      (res: {
        data: {
          count: number;
          next: string | null;
          previous: string | null;
          results: ResponseType[];
        };
      }) => {
        return {
          count: res.data.count,
          previous: res.data.previous,
          next: res.data.next,
          list: convertResultsToList(res.data.results),
        };
      }
    )
    .catch((err) => {
      console.log(err);
      throw "Error Pre Fetching Table List";
    });
}
