import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";

const palette = ["#00af9b", "#ffb800", "#ff2d55"];
const pieData = [
  { id: 0, value: 13, label: "Correct" },
  { id: 1, value: 16, label: "Not Answered" },
  { id: 2, value: 11, label: "Incorrect" },
];

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: "0.75rem",
  maxWidth: 100,
}));

// function PieCenterLabel({ children, radius, pad = 5 }) {
//   return (
//     <StyledText x={radius + pad} y={radius + pad}>
//       {children}
//     </StyledText>
//   );
// }

function ProgressPieChart({
  radius = 50,
  value: { incorrect, correct, not_answered },
}) {
  const pieData = [
    { id: 0, value: correct, label: "Correct" },
    { id: 1, value: not_answered, label: "Not Answered" },
    { id: 2, value: incorrect, label: "Incorrect" },
  ];
  const size = {
    width: 2 * (radius + 5),
    height: 2 * (radius + 5),
  };
  return (
    <PieChart
      id="progress-analytics-pie-chart"
      series={[
        {
          data: pieData,
          innerRadius: 0,
          outerRadius: radius + 5,
          paddingAngle: 0,
          cornerRadius: 3,
          startAngle: 0,
          endAngle: 360,
          cx: radius,
          cy: radius,
          highlightScope: { fade: "global", highlight: "item" },
          faded: { innerRadius: 20, additionalRadius: -10, color: "gray" },
        },
      ]}
      slotProps={{
        legend: { hidden: true },
      }}
      colors={palette}
      {...size}
      style={{ maxWidth: radius * 2 + 10 }}
    />
  );
}
export default ProgressPieChart;
