import axios from "../config/axios.ts";
import { QUESTION_TYPE, STATUS_TYPE } from "../constants/problem-states.ts";
import type { TableListType } from "../store/state-type.ts";

type ResponseTagType = { name: string };
type ResponseType = {
  title: string;
  question_type: string;
  reference_id: string;
  status: string;
  tags: ResponseTagType[];
  acceptance: number;
  time_taken: number;
  is_suggested: boolean;
};

function strToStatusType(status: string) {
  switch (status) {
    case "UA":
      return STATUS_TYPE.Attempted;
    case "C":
      return STATUS_TYPE.Correct;
    case "I":
      return STATUS_TYPE.Incorrect;
    default:
      return STATUS_TYPE.Todo;
  }
}
function strToQuestionType(status: string) {
  switch (status) {
    case "SU":
      return QUESTION_TYPE.SUBJECTIVE;
    case "SC":
      return QUESTION_TYPE.SINGLE_CORRECT;
    default:
      return QUESTION_TYPE.UNKOWN;
  }
}

function convertResultsToList(resultList: ResponseType[]): TableListType[] {
  return resultList.map((result) => {
    return {
      tags: result.tags.map((tag: ResponseTagType) => {
        return {
          name: tag.name,
        };
      }),
      title: result.title,
      status: strToStatusType(result.status),
      id: result.reference_id,
      acceptance: result.acceptance,
      isSuggested: result.is_suggested,
      timeTaken: result.time_taken,
      questionType: strToQuestionType(result.question_type),
    };
  });
}

export default async function postFetchTableList(
  filterParamUrl: string,
  signal: AbortSignal
) {
  const headers = { "X-Request-Type": "user_details" };
  return await axios
    .get(`/core/questions/?${filterParamUrl}`, {
      headers: headers,
      signal: signal,
    })
    .then(
      (res: {
        data: {
          count: number;
          next: string | null;
          previous: string | null;
          results: ResponseType[];
        };
      }) => {
        return {
          count: res.data.count,
          previous: res.data.previous,
          next: res.data.next,
          list: convertResultsToList(res.data.results),
        };
      }
    )
    .catch((err) => {
      console.log(err);
      throw "Error Post Fetching Table List";
    });
}
