import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store_v2/store";
import SubjectButtonGroup from "./subject-button-group.tsx";
import ProblemsetFilter from "./problemset-filters.tsx";
import SelectedFilterList from "./selected-filter-list.tsx";
import fetchProblemTableFilterDetails from "../../../handlers/fetch-problem-table-filter-content.ts";
import { setFilters } from "../../../store_v2/actions/tableSlice.ts";

export default function ProblemFilters() {
  const filtersState = useSelector((state: RootState) => state.table.filters);
  const dispatch = useDispatch<AppDispatch>();

  const searchParams = new URLSearchParams(useLocation().search);

  /** Fetch Filters */
  React.useEffect(() => {
    async function apiCall() {
      try {
        const res = await fetchProblemTableFilterDetails();
        dispatch(
          setFilters({ data: { ...filtersState, ...res } })
        );
      } catch (error) {
        //snackbar
      }
    }

    apiCall();
  }, []);

  return (
    <>
      <SubjectButtonGroup subjectList={filtersState.subjectList} />
      <ProblemsetFilter filters={filtersState} />
      <SelectedFilterList filters={filtersState} />
    </>
  );
}
