export enum STATUS_TYPE {
  Todo = "Unattempted",
  Attempted = "Not Answered",
  Correct = "Correct",
  Incorrect = "Incorrect",
}

export enum DIFFICULTY_TYPE {
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
}

export enum SUBJECT_TYPE {
  All = -1,
  VARC = 0,
  DILR = 1,
  Quant = 2,
}

export enum QUESTION_TYPE {
  UNKOWN = "",
  SINGLE_CORRECT = "SC",
  SUBJECTIVE = "SU",
}
export enum USER_ATTEMPT_STATUS {
  UNKOWN = "",
  UNANSWERED = "U",
  INCORRECT = "I",
  CORRECT = "C",
}
export enum USER_ATTEMPT_STATE {
  UNKOWN = "",
  SOLVED = "SOLVED",
  UNSOLVED = "UNSOLVED",
}

export const ACCEPTANCE_HIGH = 60;
export const ACCEPTANCE_LOW = 30;

//PAGINATION
export const DEFAULT_ROWS_PER_PAGE = 15;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_SEARCH_STRING = "";
