import React, { useState, useRef } from "react";
import {
  Button,
  Popper,
  Paper,
  Typography,
  Box,
  PopperPlacementType,
} from "@mui/material";
import htmlToReactParser from "../../utils/html-to-react-parser.ts";

import { styled } from "@mui/system";

const getTriangleStyles = (placement: string) => {
  switch (placement) {
    case "bottom-start":
      return {
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderBottom: "10px solid white", // Color of the triangle
        top: -10, // Position relative to the Popper
        left: "10px",
      };
    case "left":
      return {
        borderTop: "10px solid transparent",
        borderBottom: "10px solid transparent",
        borderLeft: "10px solid white", // Triangle pointing to the left
        top: "10px",
        right: -10, // Position to the left side of the Popper
      };
    default:
      return {}; // Default case for other placements if needed
  }
};

const Triangle = styled("div")<{ placement: string }>(({ placement }) => ({
  width: 0,
  height: 0,
  position: "absolute",
  ...getTriangleStyles(placement),
}));

const steps: { msg: string; elId: string; placement: PopperPlacementType }[] = [
  {
    msg: "<b>Genie Mode</b> provides AI-recommended problems<br/> tailored to your progress.<br/>Toggle it on to activate this feature!",
    elId: "is-suggested-toggle",
    placement: "bottom-start",
  },
  {
    msg: "<p style='margin-top:0'>Filter and solve problems based on your mood. 😉</p><b>Quick Note 💡</b><br/>Don’t miss out on <u>PYQs</u> in the Lists!",
    elId: "table-filter-button-List",
    placement: "bottom-start",
  },
  {
    msg: "View your topic-wise strengths and<br/> solves count right here.",
    elId: "topic-wise-analytics-tab-parent",
    placement: "left",
  },
  {
    msg: "<b>Track your progress!</b></br>Check your correct and incorrect answers &</br>aim to fill the bar with green.",
    elId: "progress-analytics-pie-chart",
    placement: "bottom-start",
  },
];

const TourGuidePopper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(() => {
    const storedState = localStorage.getItem("tour-guide-seen");
    return storedState === null; // Show popper if "tour-guide-seen" is not set
  }); // Set to true for demo purposes
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  React.useEffect(() => {
    // Find the anchor element by its ID
    const anchorElement = document.getElementById(steps[activeStep].elId);
    setAnchorEl(anchorElement);
  }, [activeStep]);

  React.useEffect(() => {
    if (!open) {
      localStorage.setItem("tour-guide-seen", "true"); // Store that the tour was shown
    }
  }, [open]);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleSkip = () => {
    setOpen(false);
  };

  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={steps[activeStep].placement}
      >
        <Paper elevation={3} style={{ position: "relative" }}>
          <Triangle placement={steps[activeStep].placement} />
          <Box padding={2}>
            <Typography variant="body2">
              {htmlToReactParser(steps[activeStep].msg)}
            </Typography>

            <Box mt={1} display="flex" justifyContent="end">
              <Button
                variant="text"
                disabled={activeStep === 0}
                onClick={handlePrevious}
                size="small"
                sx={{ mr: "auto" }}
              >
                Previous
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" onClick={handleSkip} size="small">
                  💪 Let's Go
                </Button>
              ) : (
                <>
                  <Button
                    variant="text"
                    onClick={handleSkip}
                    color="inherit"
                    size="small"
                  >
                    Skip
                  </Button>
                  <Button variant="contained" onClick={handleNext} size="small">
                    Next
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </Popper>
    </div>
  );
};

export default TourGuidePopper;
