import React from "react";
import {
  ProblemDetailsType,
  UserAttemptPostSubmitType,
} from "../../../store/state-type";
import { numToAlphabet } from "../../../utils/num-to-alphabet.ts";
import { USER_ATTEMPT_STATE } from "../../../constants/problem-states.ts";
import htmlToReactParser from "../../../utils/html-to-react-parser.ts";

import { Button, Grid, Typography } from "@mui/material";

type SolveMCQType = {
  options: ProblemDetailsType["options"] | undefined;
  optionSelected: string | null;
  setOptionSelected: React.Dispatch<React.SetStateAction<string | null>>;
  state: USER_ATTEMPT_STATE;
  postSubmit: UserAttemptPostSubmitType | undefined;
};
export default function SolveMCQ({
  optionSelected,
  setOptionSelected,
  options,
  state: attemptState,
  postSubmit,
}: SolveMCQType) {
  const correctOptId = postSubmit?.correctAnswer;
  const selectedOptId = postSubmit?.selectedAnswer;
  return (
    <>
      <Typography id="caption-text" component="p" variant="body1" ml={1}>
        Select the correct option :
      </Typography>
      <Grid container mt={1}>
        {options && options.length !== 0 ? (
          options.map((option, idx) => {
            return (
              <Grid xs={12} mb={1}>
                {/* @ts-expect-error [including] */}
                <Button
                  id={`problem-option-${idx}`}
                  variant="text"
                  type={
                    optionSelected === option.id ||
                    correctOptId === option.id ||
                    selectedOptId === option.id
                      ? "problem-option-selected"
                      : "problem-option"
                  }
                  typeVariant={
                    option.id === correctOptId
                      ? "correct"
                      : option.id === selectedOptId
                      ? "incorrect"
                      : undefined
                  }
                  fullWidth
                  sx={{
                    height: "100%",
                  }}
                  onClick={() => {
                    if (attemptState === USER_ATTEMPT_STATE.UNSOLVED)
                      setOptionSelected((currId) =>
                        currId === option.id ? null : option.id
                      );
                  }}
                >
                  <Typography
                    component="p"
                    variant="body1"
                    alignSelf="center"
                    mr={2}
                  >
                    {numToAlphabet(idx)})
                  </Typography>
                  <span>{htmlToReactParser(option?.description)}</span>
                </Button>
              </Grid>
            );
          })
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
