import React from "react";
import ListMenu from "../pages/problemset/filter-menu/list-menu.tsx";
import StatusMenu from "../pages/problemset/filter-menu/status-menu.tsx";
import PatternMenu from "../pages/problemset/filter-menu/pattern-menu.tsx";

/** MUI */
import Button from "@mui/material/Button";

/** ICON */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TopicMenu from "../pages/problemset/filter-menu/topic-menu.tsx";

type TableFilterButtonType = {
  name: string;
  filter: any;
  filterDisabled?: boolean;
};

function TableFilterButton({
  name = "Default",
  filter,
  filterDisabled = false,
}: TableFilterButtonType) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current!.focus();
  //   }
  //   prevOpen.current = open;
  // }, [open]);

  function MenuType(name: string) {
    switch (name) {
      case "List":
        return (
          <ListMenu
            listList={filter}
            open={open}
            setOpen={setOpen}
            anchorRef={anchorRef}
          />
        );
      case "Pattern":
        return (
          <PatternMenu
            patternList={filter}
            open={open}
            setOpen={setOpen}
            anchorRef={anchorRef}
          />
        );
      case "Topic":
        return (
          <TopicMenu
            topicList={filter}
            open={open}
            setOpen={setOpen}
            anchorRef={anchorRef}
          />
        );
      case "Status":
        return (
          <StatusMenu
            statusList={filter}
            open={open}
            setOpen={setOpen}
            anchorRef={anchorRef}
          />
        );
      default:
        <></>;
    }
  }

  return (
    <>
      {/**@ts-expect-error [including 'type' prop]**/}
      <Button
        ref={anchorRef}
        id={`table-filter-button-${name}`}
        variant="outlined"
        type="table-filter"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          flexGrow: 1,
          borderColor: "primary.dark",
          color: "primary.dark",
        }}
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
        disabled={filterDisabled}
      >
        {name}
      </Button>
      {MenuType(name)}
    </>
  );
}
export default TableFilterButton;
