import React from "react";
import { STATUS_TYPE } from "../../../../constants/problem-states.ts";

import { IconButton, Skeleton } from "@mui/material";
/** ICONS */
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ShareIcon from "@mui/icons-material/Share";

type StatusCellType = {
  status: string | undefined;
};
export default function StatusCell({ status }: StatusCellType) {
  const iconFontSize = "18px";
  switch (status) {
    case STATUS_TYPE.Attempted:
      return (
        <CalendarTodayOutlinedIcon
          sx={{ color: "primary.medium", fontSize: iconFontSize }}
        />
      );
    case STATUS_TYPE.Correct:
      return (
        <TaskAltOutlinedIcon
          sx={{ color: "primary.green", fontSize: iconFontSize }}
        />
      );
    case STATUS_TYPE.Incorrect:
      return (
        <HighlightOffIcon
          sx={{ color: "primary.hard", fontSize: iconFontSize }}
        />
      );
    case STATUS_TYPE.Todo:
      return <></>;
    default:
      return (
        <Skeleton variant="circular">
          <IconButton size="small">
            <ShareIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Skeleton>
      );
  }
}
