import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store_v2/store.ts";
import { updateFilterOnClick } from "../../../store_v2/actions/tableSlice.ts";
import { TableStateType } from "../../../store/state-type";

/** MUI */
import Chip from "@mui/material/Chip";
import { Stack } from "@mui/material";

type SelectedFilterListType = {
  filters: TableStateType["filters"];
};
export default function SelectedFilterList({
  filters: { listList, topicList, statusList, patternList },
}: SelectedFilterListType) {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Stack width="100%" direction="row" spacing={1} flexWrap="wrap" rowGap={1}>
      {listList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch(
                  updateFilterOnClick({
                    filterListKey: "listList",
                    filterIdx: val.index,
                  })
                );
              }}
            />
          );
        })}
      {patternList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch(
                  updateFilterOnClick({
                    filterListKey: "patternList",
                    filterIdx: val.index,
                  })
                );
              }}
            />
          );
        })}
      {topicList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch(
                  updateFilterOnClick({
                    filterListKey: "topicList",
                    filterIdx: val.index,
                  })
                );
              }}
            />
          );
        })}
      {statusList
        .filter((val) => val.isSelected)
        .map((val) => {
          return (
            <Chip
              key={val.name}
              label={val.name}
              size="small"
              onDelete={() => {
                dispatch(
                  updateFilterOnClick({
                    filterListKey: "statusList",
                    filterIdx: val.index,
                  })
                );
              }}
            />
          );
        })}
    </Stack>
  );
}
