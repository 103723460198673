import axios from "../config/axios.ts";
import { LOGIN_USER } from "../store/action-types.ts";
import type { DispatchType, StateType } from "../store/state-type.ts";

type ResponseType = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  profile_picture: string;
};

function convertResultToUserDetails(result: ResponseType): StateType["user"] {
  return {
    isLoggedIn: true,
    userDetails: {
      userFirstName: result.given_name,
      userLasttName: result.family_name,
      userEmail: result.email,
      userProfileImage: result.profile_picture,
    },
  };
}

export default async function postUserSignIn(accessToken: string) {
  if (accessToken == null) {
    console.log("access token is NULL");
    return;
  }
  const data = {
    access_token: accessToken,
  };
  return await axios
    .post(`/users/login/`, data)
    .then((res) => {
      return convertResultToUserDetails(res.data);
    })
    .catch((err) => {
      console.log(err);
      throw "Failed to login";
    });
}
