import axios from "../config/axios.ts";
import type { UserAttemptType } from "../store/state-type.ts";
import {
  getUserQuestionStatus,
  getUserQuestionState,
} from "../utils/get-user-question-type.ts";

type ResponseType = {
  question_start_time: string;
  status: string;
  time_taken: number;
  post_submit: {
    question_end_time: string;
    selected_answer: string;
    correct_answer: string;
    solution: string;
  } | null;
};

function convertResultToProblem(res: ResponseType): UserAttemptType | null {
  if (res) {
    return {
      state: getUserQuestionState(res.status),
      status: getUserQuestionStatus(res.status),
      timeTaken: res.time_taken,
      questionStartTime: res.question_start_time,
      postSubmit: res.post_submit
        ? {
            questionEndTime: res.post_submit.question_end_time,
            selectedAnswer: res.post_submit.selected_answer,
            correctAnswer: res.post_submit.correct_answer,
            solution: res.post_submit.solution,
          }
        : undefined,
    };
  }
  return null;
}

export default async function fetchUserAttemptDetails(problemId: string) {
  if (problemId == null) {
    console.error(
      "fetchUserProblemDetails handler: 'problemId' passed is undefined"
    );
    return null;
  }
  return axios
    .get(`/core/questions/${problemId}/user_question`)
    .then((res) => {
      return convertResultToProblem(res.data);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
}
