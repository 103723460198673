import { StatusListItemType, SubjectListItemType } from "../store/state-type";
import { STATUS_TYPE } from "./problem-states.ts";

/** ICONS */
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";

export const DEFAULT_SUBJECT_LIST: SubjectListItemType[] = [
  {
    index: 0,
    name: "All",
    isSelected: false,
    icon: CollectionsBookmarkOutlinedIcon,
    fullName: "All Topics",
    id: "",
  },
  {
    index: 1,
    name: "QA",
    isSelected: false,
    icon: CalculateOutlinedIcon,
    fullName: "Quantitative Ability",
    id: "",
  },
  {
    index: 2,
    name: "LRDI",
    isSelected: false,
    icon: PsychologyOutlinedIcon,
    fullName: "Data Interpretation & Logical Reasoning",
    id: "",
  },
  {
    index: 3,
    name: "VARC",
    isSelected: false,
    icon: HistoryEduOutlinedIcon,
    fullName: "Verbal Ability & Reading Comprehension",
    id: "",
  },
];
export const DEFAULT_STATUS_LIST: StatusListItemType[] = [
  {
    index: 0,
    name: "Not attempted",
    id: "NA",
    icon: RemoveIcon,
    type: STATUS_TYPE.Todo,
    isSelected: false,
  },
  {
    index: 1,
    name: "Unanswered",
    id: "UA",
    icon: CalendarTodayOutlinedIcon,
    type: STATUS_TYPE.Attempted,
    isSelected: false,
  },
  {
    index: 2,
    name: "Correct",
    id: "C",
    icon: TaskAltOutlinedIcon,
    type: STATUS_TYPE.Correct,
    isSelected: false,
  },
  {
    index: 3,
    name: "Incorrect",
    id: "I",
    icon: CloseIcon,
    type: STATUS_TYPE.Incorrect,
    isSelected: false,
  },
];
export const DEFAULT_IS_SUGGESTED_BOOL = false;
export const DEFAULT_SEARCH_STRING = "";

//PAGINATION
export const DEFAULT_ROWS_PER_PAGE = 15;
export const DEFAULT_PAGE_NUMBER = 1;
