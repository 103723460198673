import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProblemsetMain from "./components/pages/problemset/problemset-main.tsx";
import LandingPage from "./components/pages/landing/LandingPage.tsx";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store_v2/store.ts";
import { pingPongAsync } from "./store_v2/actions/userSlice.ts";

import "./App.css";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(pingPongAsync());
  }, [dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route path="/problemset" element={<ProblemsetMain />} />
        <Route path="/problem/:problemId" element={<ProblemsetMain />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/problemset" replace />} />
      </Routes>
    </div>
  );
}

export default App;
