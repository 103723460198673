import axios from "../config/axios.ts";

export default async function logOutUser() {
  return axios
    .post(`/users/logout/`)
    .then((res) => {})
    .catch((err) => {
      console.log(err);
      throw "Logout Failer";
    });
}
