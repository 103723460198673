import axios from "../config/axios.ts";
import { QUESTION_TYPE } from "../constants/problem-states.ts";
import type { UserAttemptType } from "../store/state-type.ts";
import {
  getUserQuestionState,
  getUserQuestionStatus,
} from "../utils/get-user-question-type.ts";

type ResponseType = {
  question_start_time: string;
  status: string;
  time_taken: number;
  post_submit: {
    question_end_time: string;
    selected_answer: string;
    correct_answer: string;
    solution: string;
  } | null;
};

function convertResultToProblem(res: ResponseType): UserAttemptType {
  return {
    state: getUserQuestionState(res.status),
    status: getUserQuestionStatus(res.status),
    timeTaken: res.time_taken,
    questionStartTime: res.question_start_time,
    postSubmit: res.post_submit
      ? {
          questionEndTime: res.post_submit.question_end_time,
          selectedAnswer: res.post_submit.selected_answer,
          correctAnswer: res.post_submit.correct_answer,
          solution: res.post_submit.solution,
        }
      : undefined,
  };
}

export default async function postSubmitQuestion(
  questionRefId: string,
  userAnswer: string,
  questionType: QUESTION_TYPE
) {
  if (
    userAnswer === "" ||
    questionRefId === "" ||
    questionType === QUESTION_TYPE.UNKOWN
  ) {
    console.log("sumitQuestion: empty or unkown property(ies) passed");
    throw "sumitQuestion: empty or unkown property(ies) passed";
  }
  const data =
    questionType === QUESTION_TYPE.SINGLE_CORRECT
      ? {
          option_reference_id: userAnswer,
        }
      : { answer_text: userAnswer };
  return await axios
    .post(`/core/questions/${questionRefId}/submit`, data)
    .then((res) => {
      return convertResultToProblem(res.data);
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}
