import { configureStore } from "@reduxjs/toolkit";
import userSliceReducer from "./actions/userSlice.ts";
import tableSliceReducer from "./actions/tableSlice.ts";

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    table: tableSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
