import {
  USER_ATTEMPT_STATE,
  USER_ATTEMPT_STATUS,
} from "../constants/problem-states.ts";

export function getUserQuestionStatus(type?: string): USER_ATTEMPT_STATUS {
  switch (type) {
    case "U":
      return USER_ATTEMPT_STATUS.UNANSWERED;
    case "I":
      return USER_ATTEMPT_STATUS.INCORRECT;
    case "C":
      return USER_ATTEMPT_STATUS.CORRECT;
    default:
      return USER_ATTEMPT_STATUS.UNKOWN;
  }
}

export function getUserQuestionState(type?: string): USER_ATTEMPT_STATE {
  switch (type) {
    case "U":
      return USER_ATTEMPT_STATE.UNSOLVED;
    case "I":
      return USER_ATTEMPT_STATE.SOLVED;
    case "C":
      return USER_ATTEMPT_STATE.SOLVED;
    default:
      return USER_ATTEMPT_STATE.UNSOLVED;
  }
}
