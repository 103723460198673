import React from "react";
import { USER_ATTEMPT_STATUS } from "../../../constants/problem-states.ts";
import { UserAttemptPostSubmitType } from "../../../store/state-type.ts";
import htmlToReactParser from "../../../utils/html-to-react-parser.ts";
import { TextField, Typography } from "@mui/material";

type SolveSubjectiveType = {
  postSubmit: UserAttemptPostSubmitType | undefined;
  status: USER_ATTEMPT_STATUS;
  userInput: string | null;
  setUserInput: React.Dispatch<React.SetStateAction<string | null>>;
};

const resDataObj = {
  incorrect: {
    label: "Wrong Answer",
    type: "incorrect",
    isDisabled: true,
  },
  correct: {
    label: "Correct Answer",
    type: "correct",
    isDisabled: true,
  },
  unsolved: {
    label: undefined,
    type: undefined,
    isDisabled: false,
  },
};
function getObjKey(status: USER_ATTEMPT_STATUS) {
  switch (status) {
    case USER_ATTEMPT_STATUS.INCORRECT:
      return "incorrect";
    case USER_ATTEMPT_STATUS.CORRECT:
      return "correct";
    default:
      return "unsolved";
  }
}

export default function SolveSubjective({
  postSubmit,
  status: attemptStatus,
  userInput,
  setUserInput,
}: SolveSubjectiveType) {
  const resData = resDataObj[getObjKey(attemptStatus)];
  const correctInput = postSubmit?.correctAnswer;
  const selectedInput = postSubmit?.selectedAnswer;

  function handleInputOnChange(newValue: string) {
    if (newValue === "") setUserInput(null);
    else setUserInput(newValue);
  }
  return (
    <>
      <Typography id="caption-text" component="p" variant="body1" ml={1}>
        Enter your answer below (only whole numbers are accepted) :
      </Typography>
      <TextField
        id="problem-subjective-input"
        placeholder="Type here..."
        sx={{ mt: 3 }}
        label={resData.label}
        type={resData.type}
        fullWidth
        value={selectedInput ? selectedInput : userInput ? userInput : ""}
        onChange={(e) => handleInputOnChange(e.target.value)}
        disabled={resData.isDisabled}
      />
      {attemptStatus === USER_ATTEMPT_STATUS.INCORRECT && (
        <Typography
          id="correct-subjective-text"
          component="p"
          variant="body1"
          ml={1}
          display={"flex"}
          alignItems={"center"}
        >
          <b>Correct Answer : &nbsp;</b>
          {correctInput ? htmlToReactParser(correctInput) : ""}
        </Typography>
      )}
    </>
  );
}
