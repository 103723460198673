import axios from "../config/axios.ts";
import { UserStateType } from "../store/state-type.ts";

type ResponseType = {
  email: string;
  first_name: string;
  profile_picture: string;
};

function convertResponseToUserDetails(
  response: ResponseType
): UserStateType["userDetails"] {
  return {
    userFirstName: response.first_name,
    userLasttName: null,
    userEmail: response.email,
    userProfileImage: response.profile_picture,
  };
}
export default async function getUserDetails() {
  return await axios
    .get(`/users/profile/`)
    .then((res) => {
      return convertResponseToUserDetails(res.data);
    })
    .catch((err) => {
      console.log(err);
      throw "Error fetching user details";
    });
}
