import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store_v2/store.ts";
import { GoogleLogin } from "@react-oauth/google";
import postUserSignIn from "../../handlers/user-login.ts";

/** MUI */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logOutUser from "../../handlers/user-logout.ts";
import { Badge, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  loginAsync,
  logOut,
  logoutAsync,
} from "../../store_v2/actions/userSlice.ts";

const pages = [
  { name: "Problems", badgeContent: 0, isDisabled: false },
  { name: "Test Series", badgeContent: "coming soon", isDisabled: true },
  { name: "Interview Prep", badgeContent: "coming soon", isDisabled: true },
];
const settings = ["Logout"];

type NavBarType = {};
function NavBar({}: NavBarType) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (setting: string) => {
    if (setting === "Logout") {
      dispatch(logoutAsync());
    }
    setAnchorElUser(null);
  };

  // USER
  const handleSignIn = async () => {
    try {
      await postUserSignIn("");
      dispatch(loginAsync());
    } catch (error) {
      // snackbar
    }
  };
  const handleRegister = () => {
    // googleLogin();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff" }} elevation={1}>
      {/**@ts-expect-error [ignore]*/}
      <Container maxWidth="undefined" sx={{ p: "0!important" }}>
        <Toolbar sx={{ px: "0!important", minHeight: "52px!important" }}>
          <Grid container spacing={2} alignItems="center" width="100%">
            <Grid xs={1} xl={2}></Grid>

            <Grid
              xs={8}
              xl={6.5}
              display="flex"
              flexDirection="row"
              alignItems="center"
              color="primary.text"
            >
              <Avatar
                alt="Smart Ninja"
                //@ts-expect-error [ignore]
                src={process.env.PUBLIC_URL + `/prep-genie-logo.svg`}
                variant="square"
                sx={{
                  width: 62,
                  height: 36,
                  mr: 1,
                  ":hover": { cursor: "pointer" },
                }}
                onClick={() => navigate("/")}
              />
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "flex" },
                  mt: "8px",
                }}
              >
                {pages.map((page) => (
                  <Badge
                    color="primary"
                    badgeContent={page.badgeContent}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: "xx-small!important",
                        py: 0.5,
                        height: "auto",
                      },
                      display: page.isDisabled
                        ? { xs: "none", sm: "block" }
                        : {},
                    }}
                  >
                    <Button
                      key={page.name}
                      variant="text"
                      onClick={() => console.log("Clicked")}
                      sx={{ color: "#1e1e1e", display: "block", mx: 1 }}
                      disabled={page.isDisabled}
                    >
                      {page.name}
                    </Button>
                  </Badge>
                ))}
              </Box>
            </Grid>

            <Grid
              xs={2.5}
              xl={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
            >
              {user.isLoggedIn ? (
                <>
                  <Box
                    sx={{
                      flexGrow: 0,
                    }}
                  >
                    <IconButton
                      onClick={handleOpenUserMenu}
                      size="small"
                      sx={{ p: 0 }}
                    >
                      <Avatar
                        alt={user.userDetails.userFirstName ?? "User"}
                        src={user.userDetails.userProfileImage ?? ""}
                        sx={{
                          width: 32,
                          height: 32,
                          bgcolor: "primary.main",
                          fontSize: "16px",
                        }}
                      />
                    </IconButton>
                    <Menu
                      sx={{ mt: "32px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={() => handleCloseUserMenu(setting)}
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </>
              ) : (
                // <>
                //   <Link
                //     component="button"
                //     variant="body2"
                //     onClick={() => {
                //       handleRegister();
                //     }}
                //     underline="none"
                //     fontWeight={"600"}
                //   >
                //     Registor
                //   </Link>
                //   <Typography
                //     textAlign="center"
                //     mx={1}
                //     color={"primary.text"}
                //     variant="body2"
                //   >
                //     or
                //   </Typography>
                //   <Link
                //     component="button"
                //     variant="body2"
                //     onClick={() => handleSignIn()}
                //     underline="none"
                //     fontWeight={"600"}
                //   >
                //     Sign in
                //   </Link>
                // </>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    postUserSignIn(credentialResponse.credential!!);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  // text="signin"
                  size="medium"
                  // type="icon"
                  shape="rectangular"
                />
              )}
            </Grid>

            <Grid xs={0.5} xl={1.5} />
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
