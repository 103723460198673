import axios from "../config/axios.ts";
import type { ProblemDetailsType } from "../store/state-type.ts";
import getQuestionType from "../utils/get-question-type.ts";

type ResponseTagType = { name: string };
type ResponseOptionsType = {
  description: string;
  reference_id: string;
};
type ResponseType = {
  description: string;
  options: ResponseOptionsType[];
  tags: ResponseTagType[];
  reference_id: string;
  question_type: string;
  title: string;
};

function convertResultToProblem(result: ResponseType): ProblemDetailsType {
  return {
    id: result.reference_id,
    questionType: getQuestionType(result.question_type),
    title: result.title,
    description: result.description,
    tags: result.tags.map((tag: ResponseTagType) => {
      return {
        name: tag.name,
      };
    }),
    options: result.options.map((option: ResponseOptionsType) => {
      return {
        description: option.description,
        id: option.reference_id,
      };
    }),
  };
}

export default async function fetchProblemDetails(problemId: string) {
  if (problemId == null) {
    console.error(
      "fetchProblemDetails handler: 'problemId' passed is undefined"
    );
    return null;
  }
  return axios
    .get(`/core/questions/${problemId}`)
    .then((res) => {
      return convertResultToProblem(res.data);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
}
