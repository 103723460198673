import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { SubjectListItemType } from "../../../store/state-type.ts";
import { AppDispatch } from "../../../store_v2/store.ts";
import { useDispatch } from "react-redux";
import { updateSubjectFilterOnClick } from "../../../store_v2/actions/tableSlice.ts";

type SubjectButtonGroupType = {
  subjectList: SubjectListItemType[];
};
function SubjectButtonGroup({ subjectList }: SubjectButtonGroupType) {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (idx: number) => {
    dispatch(updateSubjectFilterOnClick(idx));
  };
  return (
    <Stack direction="row" spacing={2}>
      {subjectList.map((subject, subjectIdx) => (
        //@ts-expect-error [including 'type' prop]
        <Button
          key={subject.name}
          id={`subject-button-filter`}
          // eslint-disable-next-line
          variant={subject.isSelected == true ? "contained" : "text"}
          type="subject-filter"
          size="large"
          onClick={() => handleClick(subject.index)}
          startIcon={
            <subject.icon
              id={`subject-button-filter`}
              sx={{ fontSize: "28px!important" }}
            />
          }
          sx={{
            "& .MuiButton-startIcon": {
              margin: 0,
              mb: 1,
              padding: "6px",
              borderRadius: 1,
              background: subject.isSelected ? "#00000010" : "none",
            },
          }}
        >
          {subject.fullName}
        </Button>
      ))}
    </Stack>
  );
}
export default SubjectButtonGroup;
