import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getUserDetails from "../../handlers/fetch-user-details.ts";
import pingPong from "../../handlers/ping-pong.ts";
import { UserStateType } from "../../store/state-type.ts";
import logOutUser from "../../handlers/user-logout.ts";

const initialState: UserStateType = {
  initialLoader: true,
  isLoggedIn: false,
  userDetails: {
    userFirstName: null,
    userLasttName: null,
    userEmail: null,
    userProfileImage: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn: (state, action: { payload: UserStateType["userDetails"] }) => {
      state.isLoggedIn = true;
      state.userDetails = action.payload;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.userDetails = initialState.userDetails;
    },
  },
  extraReducers(builder) {
    builder.addCase(pingPongAsync.fulfilled, (state) => {
      state.initialLoader = false;
    });
  },
});

// sets user loggedIn = true & sets user-profile
export const loginAsync = createAsyncThunk(
  "user/loginAsync",
  async (_, { dispatch }) => {
    try {
      const userDetails = await getUserDetails();
      dispatch(logIn(userDetails));
    } catch (error) {
      //display snackbar
    }
  }
);
export const logoutAsync = createAsyncThunk(
  "user/logoutAsync",
  async (_, { dispatch }) => {
    try {
      await logOutUser();
    } catch (error) {
      // display snackbar
    }
    dispatch(logOut());
  }
);
export const pingPongAsync = createAsyncThunk(
  "user/pingPongAsync",
  async (_, { dispatch }) => {
    try {
      await pingPong();
      await dispatch(loginAsync());
    } catch (error) {
      dispatch(logOut());
    }
  }
);

export const { logIn, logOut } = userSlice.actions;
export default userSlice.reducer;
