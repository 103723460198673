import React from "react";
import { useStopwatch } from "react-timer-hook";
import {
  ProblemDetailsType,
  UserAttemptType,
} from "../../../store/state-type.ts";
import timestampToString from "../../../utils/timestamp-to-string.ts";
import { USER_ATTEMPT_STATE } from "../../../constants/problem-states.ts";

import { Button, Chip, IconButton, Skeleton, Stack } from "@mui/material";
/** ICONS */
import CloseIcon from "@mui/icons-material/Close";
import BugReportIcon from "@mui/icons-material/BugReport";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

type ProblemDialogHeaderType = {
  problemId: string | null;
  handleDialogClose: () => void;
  userAttempt: UserAttemptType | null;
  tags: ProblemDetailsType["tags"] | undefined;
};

export default function ProblemDialogHeader({
  problemId,
  handleDialogClose,
  userAttempt,
  tags,
}: ProblemDialogHeaderType) {
  const elapsedTime = userAttempt ? userAttempt.timeTaken : 0;
  const isLoggedIn = userAttempt !== null;
  const isSolvedState = userAttempt
    ? userAttempt.state === USER_ATTEMPT_STATE.SOLVED
    : false;
  /** Stopwatch */
  const { totalSeconds, reset, pause } = useStopwatch({ autoStart: false });
  React.useEffect(() => {
    if (problemId === null || !isLoggedIn || isSolvedState) {
      // dialog closed [problemId = null]   (OR)   logged out [userAttempt = null]
      reset();
      pause();
    } else {
      reset();
    }
  }, [problemId, userAttempt]);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <IconButton aria-label="dialog-close-icon" onClick={handleDialogClose}>
        <CloseIcon sx={{ color: "primary.dark" }} />
      </IconButton>
      <Chip
        label={
          <Stack direction="row">
            <TimerOutlinedIcon sx={{ fontSize: "16px", pr: 1, my: "auto" }} />
            {timestampToString(
              isSolvedState ? elapsedTime : totalSeconds + elapsedTime
            )}
          </Stack>
        }
        size="medium"
        sx={{
          backgroundColor: "primary.dark",
          color: "primary.contrastText",
        }}
      />
      {tags ? (
        tags.map((tag) => {
          return <Chip label={tag.name} size="medium" />;
        })
      ) : (
        <ProblemTagsSkeleton />
      )}
      <Button
        id="problem-share-button"
        variant="text"
        startIcon={<BugReportIcon />}
        sx={{
          color: "primary.hard",
          marginLeft: "auto!important",
        }}
      >
        Report a Bug
      </Button>
    </Stack>
  );
}

function ProblemTagsSkeleton() {
  return Array.apply(null, {
    length: 3,
  }).map((_) => (
    <Skeleton>
      <Chip label="Loading" size="medium" />
    </Skeleton>
  ));
}
