import React from "react";
import { useNavigate } from "react-router-dom";
import party from "party-js";
import { GoogleLogin } from "@react-oauth/google";
import postSubmitQuestion from "../../../handlers/submit-question.ts";
import { PROBLEMS_URL } from "../../../constants/url-path.ts";
import {
  ProblemDetailsType,
  UserAttemptType,
} from "../../../store/state-type.ts";
import htmlToReactParser from "../../../utils/html-to-react-parser.ts";
import SolveMCQ from "./solve-mcq.tsx";
import SolveSubjective from "./solve-subjective.tsx";
import {
  QUESTION_TYPE,
  USER_ATTEMPT_STATE,
  USER_ATTEMPT_STATUS,
} from "../../../constants/problem-states.ts";

import { Button, Grid, Skeleton, Stack, Typography } from "@mui/material";

/** ICON */
import LockIcon from "@mui/icons-material/Lock";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AppDispatch, RootState } from "../../../store_v2/store.ts";
import { useDispatch, useSelector } from "react-redux";

type DialogRightContentType = {
  userAnswer: string | null;
  setUserAnswer: React.Dispatch<React.SetStateAction<string | null>>;
  problemDetails: ProblemDetailsType | null;
  userAttemptDetails: UserAttemptType | null;
  setUserAttemptDetails: React.Dispatch<
    React.SetStateAction<UserAttemptType | null>
  >;
  problemId: string | null;
  handleDialogClose: () => void;
};

type NavIdsType = {
  prevId: string | null;
  nextId: string | null;
} | null;

export default function DialogRightContent({
  userAnswer,
  setUserAnswer,
  problemDetails,
  userAttemptDetails,
  setUserAttemptDetails,
  problemId,
  handleDialogClose,
}: DialogRightContentType) {
  const navigate = useNavigate();
  const textRef = React.useRef<HTMLParagraphElement | null>(null);
  const [isSubmitLoading, setSubmitLoading] = React.useState(false);

  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const dispatch = useDispatch<AppDispatch>();

  /** SET NAVIGATION */
  const [navIds, setNavIds] = React.useState<NavIdsType>(null);
  const { list: tableList } = useSelector(
    (state: RootState) => state.table.data
  );
  React.useEffect(() => {
    let tempNav: NavIdsType = null;
    tableList.forEach((list, listIdx) => {
      if (list.id === problemId) {
        const prevId = listIdx - 1 >= 0 ? tableList[listIdx - 1].id : null;
        const nextId =
          listIdx + 1 < tableList.length ? tableList[listIdx + 1].id : null;
        tempNav = { prevId: prevId, nextId: nextId };
        return;
      }
    });

    if (tempNav === null) setNavIds(() => null);
    else if (tempNav.prevId == null) {
      //set page = page-1 if prevUrl!=null
      setNavIds(() => tempNav);
    } else if (tempNav.nextId == null) {
      //set page = page-1 if prevUrl!=null
      setNavIds(() => tempNav);
    } else setNavIds(() => tempNav);
  }, [tableList, problemId]);

  /**  */
  const isUserAttemptDetailsNull = userAttemptDetails !== null; //userAttemptDetails NULL means logged Out
  const isSolvedState = userAttemptDetails
    ? userAttemptDetails.state === USER_ATTEMPT_STATE.SOLVED
    : false;
  const questionType = problemDetails?.questionType ?? QUESTION_TYPE.UNKOWN;

  const handleSubmit = async () => {
    if (problemId === null || !isUserAttemptDetailsNull || isSolvedState) {
      return;
    } else if (userAnswer === null) {
      //show snackbar to give answer
    } else {
      setSubmitLoading(true);
      try {
        const submitRes = await postSubmitQuestion(
          problemId,
          userAnswer,
          questionType
        );
        setUserAttemptDetails(submitRes);

        // PARTY if CORRECT ANSWER
        if (submitRes?.status === USER_ATTEMPT_STATUS.CORRECT) {
          party.confetti(
            document.getElementById("caption-text") ?? document.body,
            {
              spread: 30,
              count: 60,
            }
          );
        }
      } catch (error) {
        //snackbar submit answer failed
      }
      setSubmitLoading(false);
    }
  };

  const handleNavigationClick = async (qId: string | null) => {
    if (navIds !== null) {
      if (qId) {
        // handleDialogClose();
        navigate(`/${PROBLEMS_URL}/${qId}`);
      } else {
        // set loader true
        // dipatch page +- 1
        // above useEffect should handle, add if(problemId==null)
      }
    }
  };

  return (
    <>
      <Stack
        height="100%"
        width="100%"
        justifyContent="space-between"
        sx={{ opacity: isLoggedIn ? 1 : 0.15 }}
      >
        <div style={{ overflowY: "scroll" }}>
          {questionType === QUESTION_TYPE.SUBJECTIVE ? (
            <SolveSubjective
              userInput={userAnswer}
              setUserInput={setUserAnswer}
              status={userAttemptDetails?.status ?? USER_ATTEMPT_STATUS.UNKOWN}
              postSubmit={userAttemptDetails?.postSubmit}
            />
          ) : questionType === QUESTION_TYPE.SINGLE_CORRECT ? (
            <SolveMCQ
              optionSelected={userAnswer}
              setOptionSelected={setUserAnswer}
              state={userAttemptDetails?.state ?? USER_ATTEMPT_STATE.UNSOLVED}
              options={problemDetails?.options}
              postSubmit={userAttemptDetails?.postSubmit}
            />
          ) : (
            <SolveLoader />
          )}
          {/* SUBMIT BUTTON */}
          {!isSolvedState ? (
            <Grid xs={12}>
              <Button
                id="problem-submit-button"
                type="submit"
                variant="contained"
                startIcon={<TaskAltIcon />}
                sx={{
                  backgroundColor: "primary.green",
                  borderRadius: "1.5rem",
                  borderColor: "primary.green",
                  width: "fit-content",
                  my: 2,
                }}
                onClick={handleSubmit}
                disabled={
                  isSubmitLoading ||
                  problemDetails === null ||
                  !isUserAttemptDetailsNull
                }
              >
                Submit Answer
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {/* SOLUTION */}
          {isSolvedState ? (
            <>
              <Typography component="p" variant="body2" ref={textRef}>
                {userAttemptDetails?.postSubmit
                  ? htmlToReactParser(userAttemptDetails.postSubmit.solution)
                  : Array.apply(null, {
                      length: 5,
                    }).map((_) => <Skeleton variant="text" animation="wave" />)}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* NAVIGATION */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mt: "auto" }}
        >
          <Button
            id="problem-previous-button"
            variant="text"
            size="large"
            startIcon={<NavigateBeforeIcon />}
            sx={{
              marginLeft: "auto!important",
              color: "primary.dark",
            }}
            onClick={() => handleNavigationClick(navIds?.prevId ?? null)}
            disabled={!!!navIds}
          >
            Previous
          </Button>
          <Button
            id="problem-next-button"
            variant="text"
            size="large"
            endIcon={<NavigateNextIcon />}
            sx={{
              color: "primary.dark",
            }}
            onClick={() => handleNavigationClick(navIds?.nextId ?? null)}
            disabled={!!!navIds}
          >
            Next
          </Button>
        </Stack>
      </Stack>
      {!isLoggedIn && <SignInOverlay />}
    </>
  );
}

function SolveLoader() {
  return (
    <>
      <Typography id="caption-text" component="p" variant="body1" ml={1}>
        <Skeleton variant="text" width={"100%"} />
      </Typography>
      {Array.apply(null, {
        length: 4,
      }).map((__) => (
        <Grid xs={12} mb={1}>
          <Button fullWidth>
            <Skeleton
              width={"100%"}
              height={"52px"}
              variant="rectangular"
              sx={{ borderRadius: "12px" }}
            />
          </Button>
        </Grid>
      ))}
    </>
  );
}
function SignInOverlay() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#f8faff",
          padding: "100px 64px",
          borderRadius: "8px",
        }}
      >
        <LockIcon sx={{ fontSize: "32px" }} />
        <Typography
          variant="h6"
          textAlign={"center"}
          mb={4}
          mt={2}
          fontWeight={600}
          maxWidth={"252px"}
        >
          Sign In so you don’t miss your progress!
        </Typography>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            // postUserSignIn(dispatch, credentialResponse.credential!!);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          // text="signin"
          size="large"
          // type="icon"
          shape="pill"
        />
      </div>
    </div>
  );
}
