import axios from "../config/axios.ts";
import { DEFAULT_SUBJECT_LIST } from "../constants/table-default-states.ts";
import type {
  ListListItemType,
  PatternListItemType,
  SubjectListItemType,
  TopicListItemType,
} from "../store/state-type.ts";

type ResponseType = {
  subjects: { name: string; reference_id: string }[];
  topics: { [x: string]: { name: string; reference_id: string }[] };
  question_types: { name: string; reference_id: string }[];
  lists: { name: string; reference_id: string }[];
  user_question_status: { name: string; reference_id: string }[];
};

type FilterListType = {
  listList: ListListItemType[];
  subjectList: SubjectListItemType[];
  topicList: TopicListItemType[];
  patternList: PatternListItemType[];
};

function convertResultToFilterList(result: ResponseType): FilterListType {
  var topicList: TopicListItemType[] = [];
  var idxCount: number = 0;
  Object.keys(result.topics).map((key) =>
    result.topics[key].map((topic) => {
      topicList.push({
        name: topic.name,
        id: topic.reference_id,
        index: idxCount,
        isSelected: false,
        subjectId: key,
      });
      idxCount++;
    })
  );

  var newSubjectList: SubjectListItemType[] = DEFAULT_SUBJECT_LIST.map(
    (subject) => {
      if (subject.name === "All") {
        return {
          ...subject,
          isSelected: true,
        };
      }
      var tempSub = result.subjects.filter(
        (val) => val.name === subject.name
      )[0];
      return {
        ...subject,
        id: tempSub.reference_id,
        isSelected: false,
      };
    }
  );

  return {
    listList: result.lists.map((list, listIdx) => {
      return {
        index: listIdx,
        id: list.reference_id,
        name: list.name,
        isSelected: false,
      };
    }),
    subjectList: newSubjectList,
    topicList: topicList,
    patternList: result.question_types.map((pattern, patternIdx) => {
      return {
        index: patternIdx,
        id: pattern.reference_id,
        name: pattern.name,
        isSelected: false,
      };
    }),
  };
}

export default async function fetchProblemTableFilterDetails() {
  // return convertResultToFilterList(mockRes);
  return await axios
    .get(`/core/content/`)
    .then((res) => {
      return convertResultToFilterList(res.data);
    })
    .catch((err) => {
      console.log(err);
      throw "Error fetching Filters";
    });
}

const mockRes: ResponseType = {
  subjects: [
    {
      name: "VARC",
      reference_id: "OQXe",
    },
    {
      name: "LRDI",
      reference_id: "nXEM",
    },
    {
      name: "QA",
      reference_id: "GSff",
    },
  ],
  topics: {
    GSff: [
      {
        name: "Algebra - Functions & Graphs",
        reference_id: "cGPw",
      },
      {
        name: "Algebra - Inequalities & Modulus",
        reference_id: "fxAJ",
      },
      {
        name: "Algebra - Logarithms",
        reference_id: "YdEk",
      },
      {
        name: "Algebra - Number System",
        reference_id: "JTyj",
      },
      {
        name: "Algebra - Number Theory",
        reference_id: "YRYp",
      },
      {
        name: "Algebra - Progressions",
        reference_id: "ECPI",
      },
      {
        name: "Algebra - Quadratic Equations",
        reference_id: "Hgko",
      },
      {
        name: "Algebra - Simple Equations",
        reference_id: "rJtT",
      },
      {
        name: "Algebra - Surds & Indices",
        reference_id: "wYbv",
      },
      {
        name: "Arithmetic - Average",
        reference_id: "cKyy",
      },
      {
        name: "Arithmetic - Mixture, Alligation, Removal & Replacement",
        reference_id: "KTAk",
      },
      {
        name: "Arithmetic - Percentage",
        reference_id: "LndT",
      },
      {
        name: "Arithmetic - Profit & Loss",
        reference_id: "jhja",
      },
      {
        name: "Arithmetic - Ratio, Proportion & Variation",
        reference_id: "GqPi",
      },
      {
        name: "Arithmetic - Simple & Compound Interest",
        reference_id: "ryuu",
      },
      {
        name: "Arithmetic - Time, Speed & Distance",
        reference_id: "gkVv",
      },
      {
        name: "Arithmetic - Time & Work",
        reference_id: "GEIy",
      },
      {
        name: "Data Sufficiency",
        reference_id: "GOQi",
      },
      {
        name: "Geometry - Basics",
        reference_id: "dqan",
      },
      {
        name: "Geometry - Circles",
        reference_id: "qlEt",
      },
      {
        name: "Geometry - Coordinate Geometry",
        reference_id: "bJkW",
      },
      {
        name: "Geometry - Mensuration",
        reference_id: "NJKG",
      },
      {
        name: "Geometry - Quadrilaterals & Polygons",
        reference_id: "QhzR",
      },
      {
        name: "Geometry - Triangles",
        reference_id: "xfNa",
      },
      {
        name: "Geometry - Trigonometry",
        reference_id: "TsrR",
      },
      {
        name: "LR - Clocks",
        reference_id: "APFD",
      },
      {
        name: "Miscellaneous",
        reference_id: "NzsN",
      },
      {
        name: "Modern Math - Permutation & Combination",
        reference_id: "eSWO",
      },
      {
        name: "Modern Math - Probability",
        reference_id: "nJzT",
      },
      {
        name: "Modern Math - Sets",
        reference_id: "IWmY",
      },
      {
        name: "Venn Diagram",
        reference_id: "oguJ",
      },
    ],
    OQXe: [
      {
        name: "Analogy",
        reference_id: "eRcH",
      },
      {
        name: "Arguments",
        reference_id: "owPt",
      },
      {
        name: "Fact, Inference & Judgement",
        reference_id: "ZpXC",
      },
      {
        name: "Fill in the blanks",
        reference_id: "QAgg",
      },
      {
        name: "Grammar, Usage & Correction",
        reference_id: "XJqH",
      },
      {
        name: "Odd one out",
        reference_id: "mEal",
      },
      {
        name: "Para Completion",
        reference_id: "yehR",
      },
      {
        name: "Para Jumbles",
        reference_id: "qJCH",
      },
      {
        name: "Para Summary",
        reference_id: "XwEo",
      },
      {
        name: "Reading Comprehension",
        reference_id: "TiBY",
      },
      {
        name: "Sentence Correction",
        reference_id: "idxr",
      },
      {
        name: "Usage",
        reference_id: "ZwbF",
      },
      {
        name: "Vocabulary",
        reference_id: "WLSu",
      },
    ],
    nXEM: [
      {
        name: "Data Sufficiency",
        reference_id: "aNNq",
      },
      {
        name: "DI - Games & Tournaments",
        reference_id: "AraZ",
      },
      {
        name: "DI - Routes & Networks",
        reference_id: "JHCj",
      },
      {
        name: "DI - Tables & Graphs",
        reference_id: "EoCA",
      },
      {
        name: "LR - Arrangements",
        reference_id: "iWvw",
      },
      {
        name: "LR - Binary Logic",
        reference_id: "ujoh",
      },
      {
        name: "LR - Blood Relation",
        reference_id: "eYdi",
      },
      {
        name: "LR - Board Games",
        reference_id: "FMGy",
      },
      {
        name: "LR - Calendar",
        reference_id: "uODs",
      },
      {
        name: "LR - Clocks",
        reference_id: "FAdX",
      },
      {
        name: "LR - Coding Decoding",
        reference_id: "qRTR",
      },
      {
        name: "LR - Cubes",
        reference_id: "NAez",
      },
      {
        name: "LR - Directions",
        reference_id: "hste",
      },
      {
        name: "LR - Logical Connectives",
        reference_id: "qNEK",
      },
      {
        name: "LR - Mathematical Reasoning",
        reference_id: "ggJr",
      },
      {
        name: "LR - Operator Based Questions",
        reference_id: "ScPe",
      },
      {
        name: "LR - Puzzles",
        reference_id: "MyjG",
      },
      {
        name: "LR - Rank & Ordering",
        reference_id: "iCFk",
      },
      {
        name: "LR - Selection & Distribution",
        reference_id: "XhVi",
      },
      {
        name: "LR - Syllogisms",
        reference_id: "MXCb",
      },
      {
        name: "LR - Venn Diagram",
        reference_id: "fMPs",
      },
    ],
  },
  question_types: [
    {
      name: "Single Correct",
      reference_id: "SC",
    },
    {
      name: "Subjective",
      reference_id: "SU",
    },
  ],
  lists: [
    {
      name: "Top 500 CAT Questions",
      reference_id: "mHBq",
    },
    {
      name: "Top 200 CAT Questions",
      reference_id: "mTBz",
    },
    {
      name: "Top 100 CAT Questions",
      reference_id: "fWBa",
    },
    {
      name: "CAT PYQ 2023 Slot-1",
      reference_id: "yWLh",
    },
    {
      name: "CAT PYQ 2023 Slot-2",
      reference_id: "PbTc",
    },
    {
      name: "CAT PYQ 2023 Slot-3",
      reference_id: "xRav",
    },
    {
      name: "CAT PYQ 2022 Slot-1",
      reference_id: "BBNt",
    },
    {
      name: "CAT PYQ 2022 Slot-2",
      reference_id: "wwGb",
    },
    {
      name: "CAT PYQ 2022 Slot-3",
      reference_id: "eRkn",
    },
    {
      name: "CAT PYQ 2021 Slot-1",
      reference_id: "DrJM",
    },
    {
      name: "CAT PYQ 2021 Slot-2",
      reference_id: "ZFmv",
    },
    {
      name: "CAT PYQ 2021 Slot-3",
      reference_id: "npOr",
    },
    {
      name: "CAT PYQ 2020 Slot-1",
      reference_id: "rYNm",
    },
    {
      name: "CAT PYQ 2020 Slot-2",
      reference_id: "LkQU",
    },
    {
      name: "CAT PYQ 2020 Slot-3",
      reference_id: "HOAu",
    },
    {
      name: "CAT PYQ 2019 Slot-1",
      reference_id: "luUc",
    },
    {
      name: "CAT PYQ 2019 Slot-2",
      reference_id: "oYFS",
    },
  ],
  user_question_status: [
    {
      name: "Unanswered",
      reference_id: "UA",
    },
    {
      name: "Not Attempted",
      reference_id: "NA",
    },
    {
      name: "Correct",
      reference_id: "C",
    },
    {
      name: "Incorrect",
      reference_id: "I",
    },
  ],
};
