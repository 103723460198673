import React from "react";
import SearchInput from "../../common/search-input.tsx";
import TableFilterButton from "../../common/table-filter-button.tsx";
import Stack from "@mui/material/Stack";
import { TableStateType } from "../../../store/state-type.ts";
import SuggestedFilter from "./filter-menu/suggested-filter.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../store_v2/store.ts";

type ProblemsetFilterType = {
  filters: TableStateType["filters"];
};
function ProblemsetFilter({ filters }: ProblemsetFilterType) {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  const topicList = filters.topicList.filter((topic) => {
    return filters.subjectList.some(
      (subject) =>
        subject.isSelected &&
        (subject.id === "" || subject.id === topic.subjectId)
    );
  });
  return (
    <Stack direction="row" spacing={1} sx={{ mt: 3, mb: 1 }}>
      <TableFilterButton name="List" filter={filters.listList} />
      <TableFilterButton name="Topic" filter={topicList} />
      <TableFilterButton name="Pattern" filter={filters.patternList} />
      <TableFilterButton
        name="Status"
        filter={filters.statusList}
        filterDisabled={!isLoggedIn}
      />
      <SearchInput placeholder="Search questions" />
      <SuggestedFilter name="Genie Mode" filter={filters.isSuggested} />
    </Stack>
  );
}
export default ProblemsetFilter;
