import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgressCard from "./progress-card.tsx";
import ProblemTable from "./problem-table.tsx";
import { filterdParseFilterParam } from "../../../utils/parse-filter-param.ts";
import TopicWiseStrength from "./topic-strength.tsx";
import ProblemDialogMain from "../problem/dialog-main.tsx";
import { RootState } from "../../../store_v2/store.ts";
import { useSelector } from "react-redux";
import NavBar from "../../common/navbar.tsx";
import Footer from "../../common/footer.tsx";

/** MUI */
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import TourGuidePopper from "../../common/guide-popper.tsx";
import ProblemFilters from "./problem-filters.tsx";

type ProblemsetMainType = {};

export type DialogUseRefType = {
  openDialog: () => void;
  closeDialog: () => void;
};

function ProblemsetMain({}: ProblemsetMainType) {
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const filtersState = useSelector((state: RootState) => state.table.filters);

  const dialogRef = React.useRef<DialogUseRefType>(null);

  const params = useParams();
  const problemId = params.problemId;

  /** Handle Route Changes */
  React.useEffect(() => {
    if (problemId) {
      dialogRef.current?.openDialog();
    } else {
      dialogRef.current?.closeDialog();
    }
  }, [params, dialogRef.current]);

  function navigateToProblemset() {
    const filterParamUrl = filterdParseFilterParam({ ...filtersState });
    navigate(`/problemset/?${filterParamUrl}`);
  }

  return (
    <>
      <NavBar />
      <Box sx={{ py: 2 }}>
        <Grid container spacing={4} width="100%">
          <Grid xs={1} xl={2}></Grid>
          <Grid xs={10} md={8} xl={6.5}>
            <ProblemFilters />
            <ProblemTable />
          </Grid>
          <Grid xs={10} sm={6} md={2.7} xl={2} pr={0} mx={"auto"}>
            <ProgressCard />
            <TopicWiseStrength />
          </Grid>
          <Grid xs={0} md={0.3} xl={1.5} />
        </Grid>
      </Box>
      <ProblemDialogMain
        problemId={problemId ?? null}
        navigateToProblemset={navigateToProblemset}
        ref={dialogRef}
      />
      <Footer />
      {isLoggedIn && <TourGuidePopper />}
    </>
  );
}
export default ProblemsetMain;
